import React from 'react';
import { Release } from '../../types/music';
import release from '../../assets/resources/musicCatalogue.json';
import './releaselisting.css';

const ReleaseListing: React.FC = ()=> {
    const allReleases = release as Release[];
    
    return(
        <div id="releases">
            {
                allReleases.map(r=>{
                    const css = `artwork ${r.id}`;
                    return r.isActive && (
                        <a href={`/audio/${r.datafile}`} key={`release-${r.id}`} download>
                            <div className="release" key={`release-${r.id}`}>
                                
                                    <div className={css}>
                                        &nbsp;
                                    </div>
                                
                                <div className="title">{r.title} 
                                    <div className="weaker subinformation">({r.released})</div>
                                </div>
                            </div>
                        </a>
                    );
                })
            }
        </div>
    );
}
export default ReleaseListing;