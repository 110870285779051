import React from 'react';
import {ANDD_DATA} from '../../assets/resources/andd';
import { Lyric } from '../../types/music';
import playButtonSvg from '../../assets/images/icons8-youtube-play.svg';
import {ReactComponent as SongTextSvg} from '../../assets/images/text-svgrepo-com.svg';
import './tracklisting.css';
const Tracklisting: React.FC = ()=> {

    const handleOnClick = (t:Lyric) => {
        document.dispatchEvent( new CustomEvent("onShowLyrics", {detail: t}) );
    }

    return (
        <table>
            <tbody>
            {
                ANDD_DATA.map(t=>{
                    return(
                        <tr  key={`release-${t.title}`}>
                            <td>{t.title}&nbsp;
                                {t.subInformation && (
                                    <span className="weaker subinformation">{t.subInformation}</span>
                                )}
                            </td>
                            <td>{t.totalLength}</td>
                            <td>
                            {t.text && (
                                <span title={`Show Lyrics '${t.title}'`} onClick={()=>handleOnClick(t.text)}>
                                    <SongTextSvg/>
                                </span>
                                ) }
                                {t.isActive && t.youTubeLink ? (
                                    <a href={t.youTubeLink} target="_blank" rel="noreferrer">
                                        <img 
                                            src={playButtonSvg.toString()} 
                                            alt={`Play '${t.title}' on YouTube!`}
                                            title={`Play '${t.title}' on YouTube!`}    
                                            />
                                    </a>
                                ) : (
                                    <img 
                                        src={playButtonSvg.toString()} 
                                        alt={`'${t.title}' not availble yet`}
                                        className={'inactive'}   
                                        />
                                )
                            
                            }
                            </td>
                        </tr>
                    )
                })
            }
            <tr>
                <td colSpan={3} style={{color: '#FFFFFF', textAlign: 'center'}}>
                <a href={"https://www.youtube.com/watch?v=l8xoUgo_Ttw&list=PL-uf8aSYjeNddWPDhix2Xq24iOC-YclRO"} target="_blank" rel="noreferrer">
                    <img 
                        src={playButtonSvg.toString()} 
                        alt={`Play the complete Album on YouTube!`}
                        title={`Play the complete Album on YouTube!`} 
                        />
                    &nbsp;Playlist on Youtube
                    </a>
                </td>
            </tr>
            </tbody>
            </table>
    )
}
export default Tracklisting;