import React from 'react';
import image from '../../assets/images/ANDD-Cover.jpg';
import './bigartwork.css';

const BigArtwork:React.FC = ()=> {
    return(
        <>
            <img alt="A New Day Dawn Artwork" src={image} id="big-artwork" />
            <div id="cut-artwork"></div>
        </>
    )
}
export default BigArtwork;