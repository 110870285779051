import { Lyric } from "../../../types/music";

export const andd: Lyric = {
    title: "A New Day Dawn",
    text: `Take your time, look around
Your safe place is part of history
Way to long you spent your time
waiting for this day to come 

But now its time to look forward
No one can take it for you
All your dreams 
are made through your will
To change things to leave behind
Please go on

Take your time (but) finally let go
Future awaits us to keep our word
Please don't fear the chance of loosing it
Still there is a reason why you're braking up

Still there are some words
Need to be said: sure it is hard
Take all memories - it will remain for you
But also make space for something new
You won't find a way to hold both of worlds
Its time to brake up

But now its time to look forward
No one can take it for you
All your dreams 
are made through your will
To change things to leave behind

Have no fear to start again
You'll find your way to Beginn
I'll make a way through your days
There'll be a new day dawn`,
}