import { useEffect, useState } from 'react';
import './App.css';
import BigArtwork from './components/bigartwork/bigartwork';
import BigPortrait from './components/bigportrait/bigportrait';
import Tracklisting from './components/tracklisting/tracklisting';
import ReleaseListing from './components/releaselisting/releaselisting';
import Divider from './components/divider/divider';
import SocialMedia from './components/socialmedia/socialmedia';
import {OPEN_LETTER_HTML,FINAL_SLOGAN_HTML} from './assets/resources/texts' ;
import { Lyric } from './types/music';
import LyricDisplay from './components/lyricDisplay/lyricsDisplay';

const App = () => {

  const [showSongText,setShowSongText] = useState<Lyric>(null);
  const [lastScroll, setLastScroll] = useState<{x:number,y:number}>({x: 0, y: 0});

  useEffect(()=>{
    if(showSongText){
      window.scrollTo(0,0);
    }else{
      window.scrollTo(lastScroll.x,lastScroll.y);
    }
  },[showSongText]);

  useEffect(()=>{
    document.addEventListener("onShowLyrics",showLyrics);
    document.addEventListener("onHideLyrics",hideLyrics);
    return () => {
      document.removeEventListener("onShowLyrics",showLyrics);
      document.removeEventListener("onHideLyrics",hideLyrics);
    }
  },[]);

  const showLyrics = (ev:CustomEvent) => {
    const x = parseInt(window.scrollX.toString(),10);
    const y = parseInt(window.scrollY.toString(),10);
    setLastScroll({x, y}); 
    setShowSongText(ev.detail as Lyric);
  }

  const hideLyrics = () => {
    setShowSongText(null);
  }

  return (
    <>
      <BigArtwork/>
      <h1 className="accented">A new Day Dawn</h1>
      <p className="letter handwritten" dangerouslySetInnerHTML={{ __html: OPEN_LETTER_HTML }} ></p>
      <div className="contentWrapper">
        <h2 className="accented">Tracklisting</h2>
        <Tracklisting/>
      </div>
      <BigPortrait/>
      <p className="handwritten centered" dangerouslySetInnerHTML={{ __html: FINAL_SLOGAN_HTML }} ></p>
      <div className="contentWrapper">
        <h2 className="accented">Download Releases</h2>
        <ReleaseListing/>
      </div>
      <Divider/>
      <SocialMedia/>
      { showSongText && (<LyricDisplay lyric={showSongText}/>)}
    </>
  );
}

export default App;
