export const FIRSTDAY = {
    title: "First day in heaven",
    text: `
i've been watching you since day one
i was there in the very moment of your birth
holding your mothers heart - taking you down to earth
what a greatfull day it was because i knew you first
and now that the day has come we see us eye to eye
i'm even more exited because today your soul will fly

i am god i see through time
i hold you hand because you're mine
even through the darkest times
i will be here right at your side
(just like) tonight

yes i was there when the first teeth went out
and of cause i saw you tears losing the first love
and then one day you decided to go with me
and am gonna tell you: noone prouder than me
and now that the day has come we see us eye to eye
i'm even more exited because today there is no more why

i am god i see through time
i hold you hand because you're mine
even through the darkest times
i will be here right at your side
and tonight oh tonight you gonna see it all

there're so many questions all over the world
the first one is always „why?“
the second one is always „why not?“
and the third one is the mighty question and never ending question of guild

||: son, beeten by his father who was a son beeten by his father who was a son :||

but now you're here
end of things 
have not fear
here you are 
just be like i ever wanted you to be
don't look back in shame or fear
everything will fall into place

everything i wanted being part of life
and i'm here to brake all circles you where in

i am god i see through time
i hold you hand because you're mine
even through the darkest times
i will be here right at your side
and tonight we´ll leave it all behind
you and me united there is no rewind
all your life i was with you
so now i will stay
its just me and you`
}