import React from "react";
import {ReactComponent as DividerSvg} from '../../assets/images/divider.svg';
import './divider.css';
const Divider:React.FC = ()=>{
    return(
        <div className="divider">
            <DividerSvg/>
        </div>
    )
}
export default Divider;