import { Track } from '../../types/music';
import { andd } from './lyrics/andd';
import { ANSWER } from './lyrics/answer';
import { eyeInTheSky } from './lyrics/eye-in-the-sky';
import { FIRSTDAY } from './lyrics/firstday';
import { FREEDOM } from './lyrics/freedom';
import { myMourning } from "./lyrics/my-mourning";
import { STEINUMSTEIN } from './lyrics/steine';
import { THINKING } from './lyrics/thinking';
import { WAYS } from './lyrics/ways';

export const ANDD_DATA: Track[] = [
    {
        title: "Freedom",
        subInformation: null,
        totalLength: "7:07",
        isActive: true,
        text: FREEDOM,
        youTubeLink: "https://youtu.be/l8xoUgo_Ttw",
    },
    {
        title: "Eye in the Sky",
        subInformation: "(Alan Parson`s Cover)",
        totalLength: "6:27",
        isActive: true,
        youTubeLink: "https://youtu.be/F9llK6488HI",
        text: eyeInTheSky,
        
    },
    {
        title: "Stein um Stein",
        subInformation: null,
        totalLength: "6:27",
        isActive: true,
        text: STEINUMSTEIN,
        youTubeLink: "https://youtu.be/jszkOigQr4s",
    },
    {
        title: "A New Day Dawn",
        subInformation: "(feat. Sven Augustin)",
        totalLength: "6:28",
        isActive: true,
        text: andd,
        youTubeLink: "https://youtu.be/XEvXxoPP_vo",
    },
    {
        title: "Thinking of you",
        subInformation: null,
        totalLength: "5:50",
        isActive: true,
        text: THINKING,
        youTubeLink: "https://youtu.be/JL2qS4BD0C8",
    },
    {
        title: "Our Ways",
        subInformation: null,
        totalLength: "3:39",
        isActive: true,
        text: WAYS,
        youTubeLink: "https://youtu.be/05opCasfUbA",
    },
    {
        title: "My Mourning",
        subInformation: null,
        totalLength: "7:43",
        isActive: true,
        text: myMourning,
        youTubeLink: "https://youtu.be/RlS1WE1dzNg",
    },
    {
        title: "First Day in Heaven",
        subInformation: null,
        totalLength: "7:57",
        isActive: true,
        text: FIRSTDAY,
        youTubeLink:"https://youtu.be/7OYDlLqsWVs",
    },
    {
        title: "Answer to your pain (John 16:33)",
        subInformation: null,
        totalLength: "6:32",
        isActive: true,
        text: ANSWER,
        youTubeLink: "https://youtu.be/47RMgii4AcI",
    },
    {
        title: "Every End is a new Start",
        subInformation: "(Bonus Track)",
        totalLength: "5:32",
        isActive: true,
        text: null,
        youTubeLink: "https://youtu.be/RoYQKMSx9S8",
        
    }
];