export const STEINUMSTEIN = {
    title: "Stein um Stein",
    text: `frag mich schon lange wie es dir gerade geht
was dich bewegt, wo du überhaupt stehst
unser gespräch viel zu lange her
haben wir uns verloren
ich weiss gerade gar nichts mehr

habe mich gefragt ob es so sein muss
das man sich heimlich und still verliert
du hast dieses leben 
ich hab meines hier
es steht auch nichts im raum
und ich brech auch nichts vom zaun
wenn ich sage
du fehlst mir

und ich weiß 
ich muss diese mauern 
abbauen

stein für stein

der erste liegt am boden
ein dicker brocken eitelkeit
und irgendwo weiter oben
liegen mehr noch schwer wie blei
fehlendes interesse
und so viel keine zeit
hau ich in die presse
zwischen wut und bitterkeit

und ich höre nicht auf
denn ich weiß es genau

denn ich denk an dich
suche dich
vermute dich 
im wirrwarr unserer zeit
denn ich ruhe nicht
schlafe nicht
bis alles was ich finde von uns bleibt
und ich gebe keine ruhe
es sein denn du schickst mich heim
und was immer ich auch tue
kein weg ist mir zu weit

stein um stein

und irgendwann kann ich licht sehen
zwischen trümmern aus dickem stein
und irgendwann werd ich dich sehen
es ist so gut wieder da zu sein

da wo du merkst das du jemand vermisst
sorg dafür, dass er dich niemals vergisst

denn er denkt an dich
sucht dich 
vermutet dich
im wirrwarr unserer zeit
und ihr ruht nicht
schlaft nicht
bis alles was ihr findet von euch bleibt
und ihr findet beide wieder ruhe
was auch war es ist vorbei 
und ihr findet beide wieder ruhe
der weg vor euch ist frei`
}