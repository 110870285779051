import { Lyric } from "../../../types/music";

export const myMourning: Lyric = {
    title: 'My Mourning',
    text: `
Breathing in first day of fall.
Letting in the mood that it brings.
Fighting back feelings of blue.
Memories abandoned coming through.

Nature returns to be reborn
Breaking down every thorn
You are still here
You take all my fear

Some people come and bring their scorn
Some people go and take what's torn
And you are still here
To take all my fear

So I will take all of my thoughts and beginn
Reaching out for your will
Beeing the man you see me
Lead me to feel free again

So that's the way it goes
It never ends
Live comes as it goes
But you are here to take my fear

So I will take all of my thoughts and beginn
Reaching out for your will
Beeing the man you see me
Lead me to feel free again

More than a feeling
More than a thought
You took all shame
For leading us home

Need to remember I was torn
Need to remember I am reborn

Every sorrow that is rising
Every pain I am dying
Every shame I may feel
There is power in your calling 
Gone with the wind my mourning
You'll give everything I need 

So I will take all of my thoughts and beginn
Reaching out for your will
Beeing the man you see me
Lead me to feel free again
`,

}
