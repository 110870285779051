export const WAYS = {
    title: "Our Ways",
    text: `so many roads left to discover
so many choices left to make

what if it is wrong
what if it is right
the truth might be not what we have expected
so it is - let's go

we won't get far until we seek our ways and learn to walk them
it's time to move 
the road is vlear my friend 
walking on our ways

i've seen you come this far
be aware of everything
but finally go

what if it is wrong
what if it is right
the truth might be not what we have expected
so it is - let's go

we won't get far until we seek our ways and learn to walk them
it's time to move 
the road is vlear my friend 
walking on our ways`
}