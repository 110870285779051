export const THINKING = {
    title: "Thinking of you",
    text: `there is something in your eyes
hard for me to identify
something hidden from the eyes
i can hardly say what it is
but a strong grown feeling stil exists
not sure if i shoudl ask you one more time

sometimes i get lost on you
there is too much laughter hidding the truth
but somehow i fear i might loose you

i have my eyes turned on you
don't tell me nothing but only the true
most people don't even have any clue
but i've seen the darkness around you
i will take care now of you
and i will listen to whatever lies within you
we will be better word by word
and i will be thinking of you

still not sure if i am right
still i need to clarify
underneath the surface i suspect a serious fight
maybe i'm completly woring
maybe all my feeling are wring
but i want to tell you i am here by your side

i have my eyes turned on you
don't tell me nothing but only the true
most people don't even have any clue
but i've seen the darkness around you
i will take care now of you
and i will listen to whatever lies within you
we will be better word by word
and i will be thinking of you

there is something in your eyes
hard for me to identify
something hidden for all eyes
sometimes i get lost on you
there is too much laughter hidding the truth
still there is my fear i might loose you

i have my eyes turned on you
don't tell me nothing but only the true
most people don't even have any clue
but i've seen the darkness around you
i will take care now of you
and i will listen to whatever lies within you
we will be better word by word
and i will be thinking of you`
}