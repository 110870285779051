import React from 'react';
import { Lyric } from '../../types/music';
import './lyricsDisplay.css';
interface LyricDisplayProps {
    lyric: Lyric;
}
const LyricDisplay: React.FC<LyricDisplayProps> = ({lyric}) => {

    const close = () => {
        document.dispatchEvent(new CustomEvent("onHideLyrics"));
    }

    return lyric && (
        <div id="lyricDisplay" onClick={close}>
            <div className={"wrapper"}>
            {lyric.title}<br/>
            {lyric.copyright && (
                <small className={"weaker"}>Copyright {lyric.copyright}</small>
            ) }<br/>
            {lyric.text}
            </div>
        </div>
    )
}

export default LyricDisplay;