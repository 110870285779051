import React from 'react';
import youTubeLogo from '../../assets/images/logos/youtube.png';
import instagramLogo from '../../assets/images/logos/instagram.png';
import './socialmedia.css';
const SocialMedia: React.FC = ()=>{

    const youTubeLink = "https://www.youtube.com/channel/UC7iGTyZwJ_G1fCSLb_0o1vg";
    const instagramLink = "https://www.instagram.com/alexhykes/?hl=de";

    return(
        <div id="logos" className="contentWrapper">
            <a href={youTubeLink}>
                <img alt="YouTube Logo" src={youTubeLogo} /></a>
            <a href={instagramLink}>
                <img alt="Instagram Logo"  src={instagramLogo} /></a>
        </div>
    )
}
export default SocialMedia;