import { Lyric } from "../../../types/music";

export const eyeInTheSky: Lyric = {
    title: "Eye in the sky",
    copyright: "Alan Parsons, Eric Woolfson, Universal Music-Careers",
    text: `
Don't think sorry is easily said
Don't try turning tables instead
You've taken lots of chances before
But ain't gonna give anymore
Don't ask me, that's how it goes
'Cause part of me knows what you're thinkin'

Don't say words you're gonna regret
Don't let the fire rush to your head
I've heard the accusation before
And I ain't gonna take any more
Believe me, the sun in your eyes
Made some of the lies worth believing

I am the eye in the sky
Looking at you
I can read your mind
I am the maker of rules
Dealing with fools
I can cheat you blind
And I don't need to see anymore
To know that
I can read your mind (looking at you)
I can read your mind (looking at you)
I can read your mind (looking at you)
I can read your mind

Don't leave false illusion behind
Don't cry I ain't changing my mind
So find another fool like before
'Cause I ain't gonna live anymore
Believing some of the lies
While all of the signs are deceiving

I am the eye in the sky
Looking at you
I can read your mind
I am the maker of rules
Dealing with fools
I can cheat you blind
And I don't need to see anymore
To know that
I can read your mind (looking at you)
I can read your mind (looking at you)
I can read your mind (looking at you)
I can read your mind

I am the eye in the sky
Looking at you
I can read your mind
I am the maker of rules
Dealing with fools
I can cheat you blind
And I don't need to see anymore
To know that
I can read your mind (looking at you)
I can read your mind (looking at you)
I can read your mind (looking at you)
I can read your mind`
}