export const FREEDOM = {
    title: "Freedom",
    text: `i see people screaming for freedom
standing on the ground their fathers build
fighting for rights never taken
fighting against their own nation

i hear people screaming for equal rights
screaming while leaving others behind
people throw away all their patients
absents of pieces - absents of rights

who's gonna lower expectations?
who's gonna clear the sky tonight? 

and in the end we all want freedom.
and in the end piece can be found
its about time to fight our daemons
don't let it getting your lights out

no matter what progress we make
no matter which solutions are found
the next critism is already waiting
the next expert is coming through the ground

don't let them shut you down
go your way - hutting for peace-
noone shall be allowed to hold you back on your way

and in the end we all want freedom.
and in the end piece can be found
its about time to fight our daemons
don't let it getting your lights out

what i'm telling you is nothing new for you
it all starts with you
it all starts with your will

piece is the key for freedom
trust is a will that has been taken first
piece is the gift you must give

and in the end there will be freedom
and in the end piece could be found
we declare the end of the daemons
we won't let them putting more lights out

and when they marching again
rememeber your decision
standing for peace
standing for law
fighting the deamons
ending the war`
}