export const ANSWER = {
    title: "Answer to your pain",
    text: `you wake up with heaviness
darkness all around
sun is shining bright but to you all is grey
knowing these kind of days you just want it to go by

[spoken] john 16, 22-24 & 33
„22 so with you: now is your time of grief, but i will see you again and you will rejoice, and no one will take away your joy. 23 in that day you will no longer ask me anything. very truly i tell you, my father will give you whatever you ask in my name. 24 until now you have not asked for anything in my name. ask and you will receive, and your joy will be complete. ... 33 “i have told you these things, so that in me you may have peace. in this world you will have trouble. but take heart! i have overcome the world.”

let go you fear, throw it out
take all of your questions,  i'll silence your doubt.
no need to run, no need to hide
live on with passion and stand up with pride
all that is torturing you - down the drain
jesus is the answer ... answer to your pain

still, all that i want: being still
fighting with the fear of being alone
trying to fight the ghosts within of darkest kind
with all of my power and all of my mind
why it is so hard to overcome all of my thoughts?
braking through.
leaving my own path behind
turning around, to the light

are you the same like me?
are you afraid of losing control?
take all your pain and all your regrets
lay it down to one who’ll give you rest

let go your fear, throw it out
take all of your questions,  i'll silence your doubt.
no need to run, no need to hide
live on with passion and stand up with pride
all that is torturing you - down the drain
jesus is, your answer ... your answer to your pain`
}