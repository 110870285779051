export const OPEN_LETTER_HTML = 'There it is: My new music! <br>'
+ 'Welcome to my very new album "A New Day Dawn". In the last couple of years I was '
+ 'working on this 60 Minutes long piece of music - and I think you hear this '
+ 'in terms of songwriting, sound and also lyrics. I really can say that I am ' 
+ 'proud of it.<br>'
+ 'Thank you for taking the time to hear and understand my music which I basically '
+ 'use to express myself and share some thoughts and feelings about the world we`re '
+ 'living in, the ways we are heading and god who wants to take part on our life.'
+ '<br>'
+ 'All the best for you!'
+ '<br>&nbsp;&nbsp;&nbsp;&nbsp; Alex';

export const FINAL_SLOGAN_HTML = '"Jesus is the answer ... answer to your pain"';